/*! reset - based off YUI with some customisation */

html {
	color: #000;
	background-color: #fff;
	-webkit-text-size-adjust: 100%;
	-ms-text-size-adjust: 100%;
}

body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
code,
form,
fieldset,
legend,
input,
textarea,
p,
blockquote,
th,
td {
	margin: 0;
	padding: 0
}

table {
	border-collapse: collapse;
	border-spacing: 0
}

fieldset,
img {
	border: 0
}

address,
caption,
cite,
code,
dfn,
var {
	font-style: normal;
	font-weight: normal
}

ol,
ul {
	list-style: none
}

caption,
th {
	text-align: left
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-size: 100%;
	font-weight: normal
}

q:before,
q:after {
	content: ""
}

abbr,
acronym {
	border: 0;
	font-variant: normal
}

input,
textarea,
select {
	font-family: inherit;
	font-size: inherit;
	font-weight: inherit
}

legend {
	color: #000
}
