/*! yamaha custom icon font */


/*
 	- generated from icomoon.io
 	- generate the woff2 font from - https://everythingfonts.com/woff-to-woff2
 	- only replace the icon definitions + variables below
 	- Do not repalce the yamicon-inline class at the bottom!!!
 	- make sure to commit
*/

$yamicon-video-sml: "\e939";
$yamicon-how-to: "\e93a";
$yamicon-maintenance: "\e93b";
$yamicon-sds: "\e93c";
$yamicon-secure-icon: "\e937";
$yamicon-question: "\e938";
$yamicon-phone: "\e900";
$yamicon-fax: "\e901";
$yamicon-zoom: "\e902";
$yamicon-drop: "\e903";
$yamicon-warning: "\e904";
$yamicon-search: "\e905";
$yamicon-times-solid: "\e906";
$yamicon-info: "\e907";
$yamicon-youtube: "\e908";
$yamicon-article: "\e909";
$yamicon-muted: "\e90a";
$yamicon-unmuted: "\e90b";
$yamicon-fullscreen: "\e90c";
$yamicon-pause: "\e90d";
$yamicon-cart: "\e90e";
$yamicon-shop: "\e90f";
$yamicon-lock: "\e910";
$yamicon-facebook: "\e911";
$yamicon-twitter: "\e912";
$yamicon-pencil: "\e913";
$yamicon-wrench: "\e914";
$yamicon-google-plus: "\e915";
$yamicon-envelope: "\e916";
$yamicon-caret-up: "\e917";
$yamicon-caret-right: "\e918";
$yamicon-caret-left: "\e919";
$yamicon-caret-down: "\e91a";
$yamicon-print: "\e91b";
$yamicon-info-circle-o: "\e91c";
$yamicon-expand: "\e91d";
$yamicon-angle-up: "\e91e";
$yamicon-angle-up-o: "\e91f";
$yamicon-arrow-down: "\e920";
$yamicon-arrow-left: "\e921";
$yamicon-arrow-right: "\e922";
$yamicon-arrow-up: "\e923";
$yamicon-award: "\e924";
$yamicon-check: "\e925";
$yamicon-close: "\e926";
$yamicon-marker: "\e927";
$yamicon-minus: "\e928";
$yamicon-play: "\e929";
$yamicon-play-circle-o: "\e92a";
$yamicon-plus: "\e92b";
$yamicon-search-thin: "\e92c";
$yamicon-sliders: "\e92d";
$yamicon-angle-down: "\e92e";
$yamicon-angle-down-o: "\e92f";
$yamicon-angle-left: "\e930";
$yamicon-angle-left-o: "\e931";
$yamicon-angle-right: "\e932";
$yamicon-angle-right-o: "\e933";
$yamicon-door: "\e934";
$yamicon-exclamation-solid: "\e935";
$yamicon-social-instagram: "\e936";

@font-face {
	font-family: yamicons;
	src:
		url("#{$fontPath}/yamicons.woff2#{$imgCacheBust}") format("woff2"),
		url("#{$fontPath}/yamicons.woff#{$imgCacheBust}") format("woff");
	font-weight: normal;
	font-style: normal;
}

.yamicon {
	/* use !important to prevent issues with browser extensions that change fonts */
	display: inline-block;
	font-family: yamicons !important;
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	font-size: inherit;
	text-transform: none;
	text-rendering: auto;
	line-height: 1;

	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.yamicon-video-sml {
  &:before {
    content: $yamicon-video-sml;
  }
}
.yamicon-how-to {
  &:before {
    content: $yamicon-how-to;     
  }
}
.yamicon-maintenance {
  &:before {
    content: $yamicon-maintenance;     
  }
}
.yamicon-sds {
  &:before {
    content: $yamicon-sds;     
  }
}
.yamicon-secure-icon {
  &:before {
    content: $yamicon-secure-icon;
  }
}
.yamicon-secure-icon {
  &:before {
    content: $yamicon-secure-icon;
  }
}
.yamicon-question {
  &:before {
    content: $yamicon-question;     
  }
}
.yamicon-phone {
	&:before {
	  content: $yamicon-phone; 
	}
  }
  .yamicon-fax {
	&:before {
	  content: $yamicon-fax; 
	}
  }
  .yamicon-zoom {
	&:before {
	  content: $yamicon-zoom;     
	}
  }
  .yamicon-drop {
	&:before {
	  content: $yamicon-drop; 
	}
  }
  .yamicon-warning {
	&:before {
	  content: $yamicon-warning;     
	}
  }
  .yamicon-search {
	&:before {
	  content: $yamicon-search; 
	}
  }
.yamicon-door {
  &:before {
    content: $yamicon-door; 
  }
}
.yamicon-exclamation-solid {
  &:before {
    content: $yamicon-exclamation-solid; 
  }
}
.yamicon-social-instagram {
  &:before {
    content: $yamicon-social-instagram; 
  }
}
.yamicon-times-solid {
  &:before {
    content: $yamicon-times-solid; 
  }
}
.yamicon-info {
	&:before {
		content: $yamicon-info;
	}
}
.yamicon-youtube {
	&:before {
		content: $yamicon-youtube;
	}
}
.yamicon-article {
	&:before {
		content: $yamicon-article;
	}
}
.yamicon-muted {
	&:before {
		content: $yamicon-muted;
	}
}
.yamicon-unmuted {
	&:before {
		content: $yamicon-unmuted;
	}
}
.yamicon-fullscreen {
	&:before {
		content: $yamicon-fullscreen;
	}
}
.yamicon-pause {
	&:before {
		content: $yamicon-pause;
	}
}
.yamicon-cart {
	&:before {
		content: $yamicon-cart;
	}
}
.yamicon-shop {
	&:before {
		content: $yamicon-shop;
	}
}
.yamicon-lock {
	&:before {
		content: $yamicon-lock;
	}
}
.yamicon-facebook {
	&:before {
		content: $yamicon-facebook;
	}
}
.yamicon-twitter {
	&:before {
		content: $yamicon-twitter;
	}
}
.yamicon-pencil {
	&:before {
		content: $yamicon-pencil;
	}
}
.yamicon-wrench {
	&:before {
		content: $yamicon-wrench;
	}
}
.yamicon-google-plus {
	&:before {
		content: $yamicon-google-plus;
	}
}
.yamicon-envelope {
	&:before {
		content: $yamicon-envelope;
	}
}
.yamicon-caret-up {
	&:before {
		content: $yamicon-caret-up;
	}
}
.yamicon-caret-right {
	&:before {
		content: $yamicon-caret-right;
	}
}
.yamicon-caret-left {
	&:before {
		content: $yamicon-caret-left;
	}
}
.yamicon-caret-down {
	&:before {
		content: $yamicon-caret-down;
	}
}
.yamicon-print {
	&:before {
		content: $yamicon-print;
	}
}
.yamicon-info-circle-o {
	&:before {
		content: $yamicon-info-circle-o;
	}
}
.yamicon-expand {
	&:before {
		content: $yamicon-expand;
	}
}
.yamicon-angle-up {
	&:before {
		content: $yamicon-angle-up;
	}
}
.yamicon-angle-up-o {
	&:before {
		content: $yamicon-angle-up-o;
	}
}
.yamicon-arrow-down {
	&:before {
		content: $yamicon-arrow-down;
	}
}
.yamicon-arrow-left {
	&:before {
		content: $yamicon-arrow-left;
	}
}
.yamicon-arrow-right {
	&:before {
		content: $yamicon-arrow-right;
	}
}
.yamicon-arrow-up {
	&:before {
		content: $yamicon-arrow-up;
	}
}
.yamicon-award {
	&:before {
		content: $yamicon-award;
	}
}
.yamicon-check {
	&:before {
		content: $yamicon-check;
	}
}
.yamicon-close {
	&:before {
		content: $yamicon-close;
	}
}
.yamicon-marker {
	&:before {
		content: $yamicon-marker;
	}
}
.yamicon-minus {
	&:before {
		content: $yamicon-minus;
	}
}
.yamicon-play {
	&:before {
		content: $yamicon-play;
	}
}
.yamicon-play-circle-o {
	&:before {
		content: $yamicon-play-circle-o;
	}
}
.yamicon-plus {
	&:before {
		content: $yamicon-plus;
	}
}
.yamicon-search-thin {
	&:before {
		content: $yamicon-search-thin;
	}
}
.yamicon-sliders {
	&:before {
		content: $yamicon-sliders;
	}
}
.yamicon-angle-down {
	&:before {
		content: $yamicon-angle-down;
	}
}
.yamicon-angle-down-o {
	&:before {
		content: $yamicon-angle-down-o;
	}
}
.yamicon-angle-left {
	&:before {
		content: $yamicon-angle-left;
	}
}
.yamicon-angle-left-o {
	&:before {
		content: $yamicon-angle-left-o;
	}
}
.yamicon-angle-right {
	&:before {
		content: $yamicon-angle-right;
	}
}
.yamicon-angle-right-o {
	&:before {
		content: $yamicon-angle-right-o;
	}
}
.yamicon-door {
	&:before {
		content: $yamicon-door; 
	}
}

// sitecore inline icons
.yamicon-inline {
	height: 32px;
	width: 32px;
}
