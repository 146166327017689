/*!
 * Hamburgers
 * @description Tasty CSS-animated hamburgers
 * @author Jonathan Suh @jonsuh
 * @site https://jonsuh.com/hamburgers
 * @link https://github.com/jonsuh/hamburgers
 */

// Settings
// ==================================================
$hamburger-padding-x                       : 15px !default;
$hamburger-padding-y                       : 15px !default;
$hamburger-layer-width                     : 20px !default;
$hamburger-layer-height                    : 2px !default;
$hamburger-layer-spacing                   : 3px !default;
$hamburger-layer-color                     : #000 !default;
$hamburger-layer-border-radius             : 0 !default;
$hamburger-hover-opacity                   : 0.7 !default;
$hamburger-hover-transition-duration       : 0.15s !default;
$hamburger-hover-transition-timing-function: linear !default;

// To use CSS filters as the hover effect instead of opacity,
// set $hamburger-hover-use-filter as true and
// change the value of $hamburger-hover-filter accordingly.
$hamburger-hover-use-filter: false !default;
$hamburger-hover-filter    : opacity(50%) !default;

// Types (Remove or comment out what you don’t need)
// ==================================================
$hamburger-types: (
  // 3dx,
  // 3dx-r,
  // 3dy,
  // 3dy-r,
  // arrow,
  // arrow-r,
  // arrowalt,
  // arrowalt-r,
  // boring,
  // collapse,
  // collapse-r,
  // elastic,
  // elastic-r,
  // emphatic,
  // emphatic-r,
  // slider,
  // slider-r,
  // spring,
  // spring-r,
  stand
  // stand-r,
  // spin,
  // spin-r,
  // squeeze,
  // vortex,
  // vortex-r
) !default;

// Base Hamburger (We need this)
// ==================================================
@import "hamburgers/base";

// Hamburger types (Each should have an @if directive
// ==================================================
@import "hamburgers/3dx";
@import "hamburgers/3dx-r";
@import "hamburgers/3dy";
@import "hamburgers/3dy-r";
@import "hamburgers/arrow";
@import "hamburgers/arrow-r";
@import "hamburgers/arrowalt";
@import "hamburgers/arrowalt-r";
@import "hamburgers/boring";
@import "hamburgers/collapse";
@import "hamburgers/collapse-r";
@import "hamburgers/elastic";
@import "hamburgers/elastic-r";
@import "hamburgers/emphatic";
@import "hamburgers/emphatic-r";
@import "hamburgers/slider";
@import "hamburgers/slider-r";
@import "hamburgers/spring";
@import "hamburgers/spring-r";
@import "hamburgers/stand";
@import "hamburgers/stand-r";
@import "hamburgers/spin";
@import "hamburgers/spin-r";
@import "hamburgers/squeeze";
@import "hamburgers/vortex";
@import "hamburgers/vortex-r";

// ==================================================
// Cooking up additional types:
//
// The Sass for each hamburger type should be nested
// inside an @if directive to check whether or not
// it exists in $hamburger-types so only the CSS for
// included types are generated.
//
// e.g. hamburgers/types/_new-type.scss
//
// @if index($hamburger-types, new-type) {
//   .hamburger--new-type {
//     ...
//   }
// }
