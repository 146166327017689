/*! custom switch resets */


html,
body {
	overflow: hidden;
}

html {
	overflow-y: scroll;
	box-sizing: border-box;
	-ms-overflow-style: scrollbar;
}
*,
*:before,
*:after {
	box-sizing: inherit;
}

// remove spinner on number input fields
input[type="number"] {
	&::-webkit-inner-spin-button,
	&::-webkit-outer-spin-button {
		margin: 0;
		-webkit-appearance: none;
	}
	-moz-appearance: textfield !important;
}

iframe {
	border: 0;
}

video {
	object-fit: cover;
}

strong {
	font-weight: bold;
}

button:focus {
	outline: none;
}
