/*! variables */


// layout
// --------------------------------------------------

// breakpoints
$initiate: 767px; // mobile max-width
$padawan: 768px; // tablet
$knight: 1068px; // desktop mid
$master: 1368px; // desktop large
$padawanMax: ($knight - 1);
$knightMax: ($master - 1);
@include add-breakpoint("initiate", screen 0 $initiate);
@include add-breakpoint("padawan", screen $padawan);
@include add-breakpoint("padawanIsolate", screen $padawan $padawanMax);
@include add-breakpoint("padawanMax", screen 0 $padawanMax);
@include add-breakpoint("knight", screen $knight);
@include add-breakpoint("knightIsolate", screen $knight $knightMax);
@include add-breakpoint("knightMax", screen 0 $knightMax);
@include add-breakpoint("master", screen $master);

// sizes
$max-width: 2560px;
$header-height: 55px;
$header-height-large: 90px;
$mobile-nav-quick-links: 76px;
$footer-nav-height: 456px;
$footer-copy-height: 40px;
$footer-height: floor($footer-nav-height + $footer-copy-height);

// gutter
$gutterBase: 12;
@function gutterSize($gutterMultiplier) {
	@return ($gutterBase * $gutterMultiplier) * 1px;
}


// z-index
// --------------------------------------------------

$zindexReset: 1;
$zindex: (
	penthouse: 99999,
	level5: 9999,
	level4: 999,
	level3: 99,
	level2: 9,
	// level1.2: 2,
	level1: $zindexReset,
	ground: 0,
	basement: -1,
	hell: -99999
);
@function zindex($key) {
	@if not map-has-key($zindex, $key) {
		@warn "zindex '#{$key}' not found.";
	}
	@return map-get($zindex, $key);
} // z-index: zindex(level1);

// blacks
$black: #000;
$black-light: #111;

// whites
$white: #fff;
$white-dark: #f5f5f5;

// greys
$grey-darkest: #222;
$grey-darker: #444;
$grey-dark: #666;
$grey-mid: #999;
$grey-light: #aaa;
$grey-lighter: #ccc;
$grey-lightest: #eaeaea;
$grey-alt: #f5f5f5;

$brand-primary: #f00;
$brand-light: lighten($brand-primary, 10%);
$brand-dark: darken($brand-primary, 10%);
.brand-light {
	background-color: $brand-light;
}
.brand-dark {
	background-color: $brand-dark;
}
$brand-blue: #0a2d82;
$brand-marine-blue: #236bb3;
$brand-light-grey: $grey-dark;
$brand-dark-grey: #333;
$brand-green: #4da60b;

// contextual
$brand-success: #6ebd14;
$brand-info: #5bc0de;
$brand-warning: #ffd204;
$brand-danger: #e51b24;

// type
$text-color: #444;
$text-color-light: lighten($text-color, 20%);
$text-color-dark: darken($text-color, 10%);
$headings-color: #000;
$link-color: $brand-primary;
$link-color-blue: #0000ee;
$link-hover-color: darken($link-color, 10%);
$scrollbar-color: #d8d8d8;
.text--uppercase {
	text-transform: uppercase;
}

// 3rd party
$colour-addthis: #ff5c3e;
$colour-facebook: #3b5998;
$colour-flickr: #ff0084;
$colour-google: #cf4332;
$colour-instagram: #3f729b;
$colour-linkedin: #0077b5;
$colour-pinterest: #cc2027;
$colour-slack: #3aaf85;
$colour-skype: #00aff0;
$colour-twitter: #55acee;
$colour-vimeo: #1ab7ea;
$colour-yahoo: #410093;
$colour-youtube: #ae2b25;

// labels 
$label-blue: #5d98dc;
$label-grey: #545454;


// timings
// --------------------------------------------------

$duration: 0.4s;
$duration-short: 0.2s;
$duration-long: 0.8s;
$delay: 0.4s;
$delay-short: 0.2s;
$delay-long: 0.8s;


// forms
// --------------------------------------------------

$input-bg-disabled: transparent;
$input-disabled-box-shadow: inset 0 3px 0 0 $white-dark;
$form-box-shadow: inset 0 0 3px rgba($black, 0.1);
$radio-checkbox-box-shadow: inset 0 3px 3px $grey-light;
$radio-checkbox-hover-box-shadow: inset 0 0 12px 3px rgba($brand-primary, 0.15), inset 0 3px 3px $grey-light;


// typography
// --------------------------------------------------

// line height
// $line-height-base: 1.5;
// $line-height-headings: 1.1;

// typesettings
$font-sans: Roboto, 'Helvetica Neue', Helvetica, Arial, sans-serif;
$font-serif: Georgia, 'Times New Roman', serif;
$font-mono: 'Lucida Console', Monaco, monospace;
$base-vertical-unit: 6px;
$base-line-multi: 4;
$base-font-size: 16px;
$ms-ratio: 1.25;
$paragraph-indent: true;
$paragraph-justify: false;
$load-typesetted: false;

// fonts
$font-family-base: $font-sans;
$font-family-light: "Helvetica Neue", Helvetica, Arial, sans-serif;
$headings-font-family: Oswald, "Helvetica Neue", Helvetica, Arial, sans-serif;
$brand-font-family: $headings-font-family;

// convert fonts to percentages
$fontReset: $base-font-size;
$font: (
	36px: ((36px / $fontReset) * 100%),
	34px: ((34px / $fontReset) * 100%),
	32px: ((32px / $fontReset) * 100%),
	30px: ((30px / $fontReset) * 100%),
	28px: ((28px / $fontReset) * 100%),
	26px: ((26px / $fontReset) * 100%),
	25px: ((25px / $fontReset) * 100%),
	24px: ((24px / $fontReset) * 100%),
	23px: ((23px / $fontReset) * 100%),
	22px: ((22px / $fontReset) * 100%),
	21px: ((21px / $fontReset) * 100%),
	20px: ((20px / $fontReset) * 100%),
	19px: ((19px / $fontReset) * 100%),
	18px: ((18px / $fontReset) * 100%),
	17px: ((17px / $fontReset) * 100%),
	16px: ((16px / $fontReset) * 100%),
	15px: ((15px / $fontReset) * 100%),
	14px: ((14px / $fontReset) * 100%),
	13px: ((13px / $fontReset) * 100%),
	12px: ((12px / $fontReset) * 100%),
	11px: ((11px / $fontReset) * 100%),
	10px: ((10px / $fontReset) * 100%)
);
@function fontSize($key) {
	@if not map-has-key($font, $key) {
		@warn "fontSize '#{$key}' not found.";
	}
	@return map-get($font, $key);
}


// general
// --------------------------------------------------

$imgPath: "/assets/images"; // background: url("#{$imgPath}/pngTrans.png") repeat-x;
$imgCacheBust: "?v=#{random(9000) + 999}";
$fontPath: "/assets/fonts";
$border-radius: 2px;


// buttons
// --------------------------------------------------
$button-padding: 0.6em 1.5em;
$button-margin: 0;

// tiles
// --------------------------------------------------
$tileBoxShadow: 0 1px 0 rgba(0, 0, 0, 0.08);
