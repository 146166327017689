/*! mixins */


// typography
// --------------------------------------------------

// layout breaking long words
@mixin word-wrap() {
	hyphens: auto;
	word-break: break-word;
	overflow-wrap: break-word;
	word-wrap: break-word;
}

// add ellipsis when type extends past its container
@mixin ellipsis() {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}


// components
// --------------------------------------------------

// hover
@mixin underline($width: 0) {
	@include transition(width $duration $ease-in-circ);
	position: absolute;
	bottom: 0;
	left: 0;
	width: $width;
	height: 3px;
	overflow: hidden;
	background-color: $brand-primary;
	content: "";
}

// style colour of form placeholders
@mixin placeholderStyling {
	&::-webkit-input-placeholder { @content; }
	&::-moz-placeholder { @content; }
	&:-ms-input-placeholder { @content; } // put this pseudo selector on fields themselves
}

// to wrap or not to wrap .ww
@mixin wrapWw($boolean) {
	@if $boolean {
		.ww {
			@content;
		}
	}
	@else {
		@content;
	}
}

// style autocomplete styling for webkit
// https://css-tricks.com/snippets/css/change-autocomplete-styles-webkit-browsers/
// use this sparingly, only on major sitewide search fields
@mixin autocompleteStyling {
	&:-webkit-autofill,
	&:-webkit-autofill:hover,
	&:-webkit-autofill:focus {
		@content;
	}
}

// yamicon
// use when creating custom yamicons using pseudo elements and you cannot use the yamicon class
@mixin yamicon {
	font-family: yamicons !important;
	font-style: normal;
	font-variant: normal;
	font-weight: normal;
	line-height: 1;
	text-rendering: auto;
	text-transform: none;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	speak: none;
}
